<template>
	<div>
		<el-result style="margin-top: 60px;" icon="success" title="意向提交成功" subTitle="开课后会第一时间联系您">
		    <template slot="extra">
				<el-button type="primary" size="medium" @click="handledClick()">返回</el-button>
		    </template>
		</el-result>
	</div>
</template>

<script>
	export default {
		name: "SubmitResult",
    methods:{
      handledClick(){
        const url = this.$router.push({ name: 'TalentProgress' });
      }
    }
	}
</script>

<style scoped>
</style>